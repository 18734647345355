import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Events, AlertController, LoadingController, NavParams, ModalController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { Storage } from '@ionic/storage';
import { NewAddressPage } from "../new-address/new-address.page";
import { UtilsService } from '../services/utils/utils.service';
export class SelectAddressPage {
    constructor(events, router, alertController, loadingController, storage, navParams, UtilsService, modalController, route) {
        this.events = events;
        this.router = router;
        this.alertController = alertController;
        this.loadingController = loadingController;
        this.storage = storage;
        this.navParams = navParams;
        this.UtilsService = UtilsService;
        this.modalController = modalController;
        this.route = route;
        this.addressesData = [];
        this.showLoader = true;
        this.showAddAddressBtn = false;
        this.addressType = 'shipping';
        this.addressType = navParams.get('type');
    }
    ngOnInit() {
        this.initializeSubscription();
        this.events.publish('user:getAllSavedAddresses');
        setTimeout(() => {
            this.showAddAddressBtn = true;
        }, 500);
    }
    ionViewWillEnter() {
        this.storage.get('userDefaultAddress').then((address) => {
            this.addressFromStorage = address;
        });
    }
    ngOnDestroy() {
        this.removeSubscription();
    }
    initializeSubscription() {
        this.events.subscribe('user:publishAllSavedAddresses', (allAddresses) => {
            this.addressesData = allAddresses;
            this.UtilsService.consoleLog('address modal address', this.addressesData);
            this.showLoader = false;
        });
    }
    /*radioSelect(event) {
      //// console.log(event.target.value);
      this.selectedAddress = event.target.value;
    }*/
    modalDismiss() {
        this.modalController.dismiss();
    }
    selectAddress(address) {
        this.selectedAddress = address;
    }
    goToPage(page) {
        const navigationExtras = {
            state: {
                routeFromSelectAddress: true,
                type: this.addressType
            }
        };
        this.router.navigate([page], navigationExtras);
    }
    addNewAddress() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modal = yield this.modalController.create({
                component: NewAddressPage,
                cssClass: 'custom-modal',
                componentProps: {
                    routeFromSelectAddress: true,
                    type: this.addressType
                }
            });
            yield modal.present();
        });
    }
    editAddress(address) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modal = yield this.modalController.create({
                component: NewAddressPage,
                cssClass: 'custom-modal',
                componentProps: {
                    addressData: address,
                    addressLength: this.addressesData.length,
                    routeFromSelectAddress: true,
                    type: this.addressType
                }
            });
            yield modal.present();
        });
    }
    onClickDeliverHere() {
        if (!this.selectedAddress) {
            this.selectedAddress = this.addressesData[0];
        }
        if (this.addressType === 'shipping') {
            this.storage.set('userDefaultAddress', this.selectedAddress);
        }
        else {
            this.storage.set('userBillingAddress', this.selectedAddress);
        }
        this.modalDismiss();
    }
    removeSubscription() {
        this.events.unsubscribe('user:publishAllSavedAddresses');
    }
}
