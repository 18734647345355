import * as tslib_1 from "tslib";
import { ConfigService } from '../config/config.service';
import { MultiCountriesService } from '../multi-countries/multi-countries.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { first } from 'rxjs/operators';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { LabelService } from '../label/label.service';
import * as i0 from "@angular/core";
import * as i1 from "../config/config.service";
import * as i2 from "../multi-countries/multi-countries.service";
import * as i3 from "@angular/fire/firestore";
import * as i4 from "@ionic/angular";
import * as i5 from "../label/label.service";
export class SharedNewService {
    constructor(configService, multiCountriesService, afs, alertController, labelService, modalController, navCtrl) {
        this.configService = configService;
        this.multiCountriesService = multiCountriesService;
        this.afs = afs;
        this.alertController = alertController;
        this.labelService = labelService;
        this.modalController = modalController;
        this.navCtrl = navCtrl;
    }
    showQtyLeftMsg(product, index = 0) {
        const qtyLeftMsgCount = this.configService.environment.qtyLeft || 5;
        let quantity = 0;
        quantity = product.isPriceList ? +product.priceList[index].totalQuantity || 0 : +product.productQty || 0;
        return quantity > 0 && quantity <= qtyLeftMsgCount ? quantity : 0;
    }
    showPriceListQtyLeftMsg(quantity) {
        const qtyLeftMsgCount = this.configService.environment.qtyLeft || 5;
        return quantity > 0 && quantity <= qtyLeftMsgCount ? quantity : 0;
    }
    getPriceBasedOnExhangeRate(price) {
        if (this.multiCountriesService.multiCountriesSettings.settings.autoExchange) {
            const toRate = this.multiCountriesService.rates[this.configService.environment.currencyCode];
            const fromRate = this.multiCountriesService.rates['INR'];
            return ((toRate / fromRate) * price);
        }
        else {
            return price;
        }
    }
    getTemplateData(templateId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const templateData = yield this.afs.collection('templates').doc(templateId).valueChanges().pipe(first()).toPromise();
            return templateData || null;
        });
    }
    presentAlert(alertData) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                message: alertData.msg,
                backdropDismiss: alertData.backdropDismiss,
                buttons: [{
                        text: `${this.labelService.labels['SHARED']['ok']}`,
                        handler: () => {
                            if (alertData.page) {
                                this.navCtrl.navigateRoot([alertData.page]);
                            }
                            else {
                                return;
                            }
                        }
                    }]
            });
            yield alert.present();
        });
    }
}
SharedNewService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SharedNewService_Factory() { return new SharedNewService(i0.ɵɵinject(i1.ConfigService), i0.ɵɵinject(i2.MultiCountriesService), i0.ɵɵinject(i3.AngularFirestore), i0.ɵɵinject(i4.AlertController), i0.ɵɵinject(i5.LabelService), i0.ɵɵinject(i4.ModalController), i0.ɵɵinject(i4.NavController)); }, token: SharedNewService, providedIn: "root" });
