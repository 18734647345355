import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { DeliveryService } from 'src/app/services/delivery/delivery.service';
import { LabelService } from 'src/app/services/label/label.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { StatesModalPage } from '../../states-modal/states-modal.page';
var markerDragged = false;
export class PickDropMapPage {
    constructor(modalController, loadingController, deliveryService, sharedService, labelService) {
        this.modalController = modalController;
        this.loadingController = loadingController;
        this.deliveryService = deliveryService;
        this.sharedService = sharedService;
        this.labelService = labelService;
        this.lat = 0;
        this.lng = 0;
        this.address = {
            address: '',
            city: '',
            state: '',
            pincode: '',
        };
        this.showMap = false;
        this.pickupDate = new Date().toISOString();
        console.log('choice:', this.choice);
        this.address.state = this.pickupState;
    }
    ngOnInit() {
    }
    ionViewWillEnter() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.PICK_AND_DROP_MAP = this.labelService.labels['PICK_AND_DROP_MAP'];
            this.SHARED = this.labelService.labels['SHARED'];
        });
    }
    closeModal() {
        this.modalController.dismiss();
    }
    openStateModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modal = yield this.modalController.create({
                component: StatesModalPage,
                backdropDismiss: false,
            });
            modal.onDidDismiss()
                .then((res) => {
                if (res.data) {
                    this.address.state = res.data.state;
                }
            });
            yield modal.present();
        });
    }
    disableShowArea() {
        for (let property in this.address) {
            if (this.address[property] == '') {
                return true;
            }
        }
        return false;
    }
    disableSave() {
        if (this.lat == 0 || this.lng == 0) {
            return true;
        }
        return false;
    }
    getLatLong() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.presentLoading(`${this.PICK_AND_DROP_MAP['fetching_area']}`);
            const latLngObj = yield this.deliveryService.getLatLngFromAddress(this.address);
            console.log('latLngObj:', latLngObj);
            if (latLngObj) {
                this.lat = latLngObj.results[0].geometry.location.lat;
                this.lng = latLngObj.results[0].geometry.location.lng,
                    this.showMap = true;
                setTimeout(() => {
                    this.loadMap();
                }, 500);
                this.loading.dismiss();
            }
            else {
                this.loading.dismiss();
                this.sharedService.presentAlert(`${this.PICK_AND_DROP_MAP['please_try_again_later']}`);
            }
        });
    }
    saveArea() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if ((!this.pickupTime && this.choice == 'pickup')) {
                this.sharedService.presentAlert(`${this.PICK_AND_DROP_MAP['please_select_pickup_time']}`);
            }
            else {
                yield this.presentLoading(`${this.SHARED['please_wait']}`);
                let latLngObj = {
                    lat: this.marker.getPosition().lat(),
                    lng: this.marker.getPosition().lng()
                };
                if (markerDragged) {
                    const address = yield this.deliveryService.getAddressFromLatLng(latLngObj);
                    this.address.address = address.results[0].formatted_address;
                }
                let obj = {
                    address: this.address,
                    latLng: latLngObj,
                    pickupDate: this.pickupDate,
                    pickupTime: this.pickupTime,
                };
                this.loading.dismiss();
                setTimeout(() => {
                    this.modalController.dismiss(obj);
                }, 500);
            }
        });
    }
    presentAlert() {
        this.sharedService.presentAlert(`${this.PICK_AND_DROP_MAP['state_and_city_same']}`);
    }
    presentLoading(msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loading = yield this.loadingController.create({
                message: msg,
                duration: 3000
            });
            this.loading.present();
        });
    }
    loadMap() {
        let latLng = new google.maps.LatLng(this.lat, this.lng);
        let mapOptions = {
            center: latLng,
            zoom: 15,
            scrollwheel: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
        let marker = new google.maps.Marker({
            map: this.map,
            animation: google.maps.Animation.DROP,
            draggable: true,
            position: this.map.getCenter()
        });
        this.marker = marker;
        new google.maps.event.addListener(this.marker, 'dragend', function (event) {
            markerDragged = true;
        });
    }
}
