import * as tslib_1 from "tslib";
import { OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { LabelService } from '../services/label/label.service';
import { SharedService } from '../services/shared/shared.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { WidgetsService } from '../services/widgets/widgets.service';
import { encodeURL } from '../services/parseURL';
export class PromoModalPage {
    constructor(router, utilsService, modalController, labelService, ngZone, sharedService, widgetService) {
        this.router = router;
        this.utilsService = utilsService;
        this.modalController = modalController;
        this.labelService = labelService;
        this.ngZone = ngZone;
        this.sharedService = sharedService;
        this.widgetService = widgetService;
    }
    ngOnInit() {
        // console.log(this.popup);
        this.PROMO_POPUP_LABELS = this.labelService.labels['PROMO_POPUP'];
        this.CO_CATEGORY_GRID_LABELS = this.labelService.labels['CO_CATEGORY_GRID'];
    }
    onClickImgDocument(event) {
        console.log('onClickImgDocument');
        if (!this.imageBox.nativeElement.contains(event.target)) { // or some similar check
            this.ngZone.run(() => {
                this.closePopup();
            });
        }
    }
    closePopup() {
        this.modalController.dismiss();
    }
    onClickPopup() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.closePopup();
            const linkType = this.popup.link.type;
            const id = this.popup.link.id;
            const name = this.popup.link.name;
            if (linkType === this.PROMO_POPUP_LABELS['category']) {
                // const category: any = await this.widgetService.getCBSDataFromId(id, 'categories');
                // this.sharedService.manageExclusiveCategories(category.isExclusive || false, id, 'parentCategory');
                let cname = encodeURL(name);
                if (this.popup.link.hasOwnProperty('isSubcategories') && this.popup.link.isSubcategories) {
                    this.router.navigate([`shop-subcategories/${cname}/${id}`]);
                }
                else {
                    this.router.navigate([`shop/category/${cname}/${id}`]);
                }
            }
            else if (linkType === this.PROMO_POPUP_LABELS['brand']) {
                let brandname = encodeURL(name);
                this.router.navigate([`shop/brand/${brandname}/${id}`]);
            }
            else if (linkType === this.PROMO_POPUP_LABELS['service']) {
                const res = yield this.sharedService.checkLoginStatus(`${this.CO_CATEGORY_GRID_LABELS['must_be_user_to_access_services_msg']}`);
                if (res) {
                    const navigationExtras = {
                        state: {
                            serviceData: this.popup.link.serviceData
                        }
                    };
                    this.router.navigate(['service-response'], navigationExtras);
                }
            }
            else if (linkType === this.PROMO_POPUP_LABELS['refer_and_earn'] || linkType === 'referEarn') {
                const userId = yield this.sharedService.getStorageUid();
                if (!userId) {
                    this.utilsService.openLoginModal();
                }
                else {
                    this.router.navigate(['user-referral']);
                }
            }
            else if (linkType === this.PROMO_POPUP_LABELS['search']) {
                const navigationExtras = {
                    state: {
                        searchTxt: name
                    }
                };
                this.router.navigate(['search-items'], navigationExtras);
            }
            else if (linkType === this.PROMO_POPUP_LABELS['product']) {
                let productName = encodeURL(name);
                this.router.navigate([`product-details/${productName}/${id}`]);
            }
            else if (linkType === this.PROMO_POPUP_LABELS['membership'] || linkType === 'membership') {
                const userId = yield this.sharedService.getStorageUid();
                if (!userId) {
                    this.utilsService.openLoginModal();
                }
                else {
                    this.router.navigate(['membership']);
                }
            }
            else if (linkType === 'external') {
                if (this.popup.link.url) {
                    window.open(this.popup.link.url, '_blank');
                }
            }
            else if (linkType === 'contactUs') {
                this.router.navigate(['contact']);
            }
            else if (linkType === 'feedback') {
                const userId = yield this.sharedService.getStorageUid();
                if (!userId) {
                    this.utilsService.openLoginModal();
                }
                else {
                    this.router.navigate(['user-feedback']);
                }
            }
            else if (linkType === 'offers') {
                this.router.navigate(['all-offers']);
            }
            else {
                return null;
            }
        });
    }
}
